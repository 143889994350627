.action-btn{
    color: #000 !important;
    padding: 8px;
    font-size: 15px;
}
.badge.badge-success.custom-color,span.badge.badge-danger.custom-color{
color: white !important;
    border: none;
}
.btn.btn-light {
    color: #7e8299;
    background-color: #f3f6f9;
    border-color: #f3f6f9;
}
.btn-group-sm>.btn.btn-icon, .btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}
.svg-icon.svg-icon-primary svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #6993ff!important;
}
.btn.btn-hover-primary.focus:not(.btn-text), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff!important;
    background-color: #6993ff!important;
    border-color: #6993ff!important;
}
.btn.btn-hover-primary.focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #fff!important;
}


th.MuiTableCell-root.MuiTableCell-head {

    font-weight: 600;
    color: #b5b5c3!important;
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
}

th.MuiTableCell-root.MuiTableCell-head .MUIDataTableHeadCell-sortActive-57, .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #6993ff!important;
}

.label {
    padding: 0;
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: .8rem;
    background-color: #ebedf3;
    color: #3f4254;
    font-weight: 400;
    height: 20px;
    width: 20px;
    font-size: .8rem;
}
.label.label-inline {
    width: auto;
}

.label.label-inline {
    width: auto;
    padding: .15rem .75rem;
    border-radius: .42rem;
}


.label.label-inline.label-lg {
    padding: .9rem .75rem;
}
.label.label-lg {
    height: 24px;
    width: 24px;
    font-size: .9rem;
}
.label.label-lg.label-inline {
    width: auto;
}

.label.label-light-success {
    color: #1bc5bd;
    background-color: #c9f7f5;
}
.label.label-light-warning {
    color: #ffa800;
    background-color: #fff4de;
}


.MuiTableCell-body{
    font-size: 1.08rem;
}
.MuiTableCell-body{
    color: #3f4254!important;
}
.MuiTableCell-body {
    font-weight: 600!important;
}

.MuiTableCell-root {
 
    border-bottom: 2px solid #ebedf3 !important;
    
}
