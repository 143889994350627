.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}


.Invalid {
    border: 1px solid #e61711 !important;
    /* background-color: #FDA49A !important; */
}
.error {
    position: relative;
}
.align-custom-class{
    position: absolute;
    top: -30px;
    right: 11px;
    border-radius: 50%;
    border: 1px solid #e61711;
    padding: 3px 6px;
    font-size: 11px;
}
.align-custom-class-otp{
    position: absolute;
    top: -30px;
    right: 42%;
    border-radius: 50%;
    border: 1px solid #e61711;
    padding: 3px 6px;
    font-size: 11px;
}
 
.success {
    position: relative;
    color:green;
}
.success .align-custom-class,.success .align-custom-class-otp{
    color:green;
    border: 1px solid green;

}
/*replace the content value with the
corresponding value from the list below*/
/*  
.error:before {
    content: "&#xf12a;";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
/*--adjust as necessary--
    color: #000;
    font-size: 18px;
    padding-right: 0.5em;
    position: absolute;
    top: 10px;
    left: 0;
} */
.leftText{
    font-size: 14px;
    font-weight: 600;
    position: relative;
    top: -17px;
    margin-right: 7px;

}
.error{
    color:#e61711;
}
.input-inline-block{
    width:60% !important;
    display:inline-block !important;
}
.button-padding{
    padding-top: 8px !important;
    padding-bottom: 7px !important;
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth {
    margin: 0;
}
.close-btn-f{
    font-size: 38px;
    cursor: pointer;
}
.requiredField{
    color:#e61711;
    
}

.form-control.form-control-solid {
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
}
/******Module section css*******/
.question-task-list{
    background: #f3f6f9;
        border-radius: 14px;
        font-size: 15px;
        margin-top: 7px;
        padding: 6px;
        color: black;
        box-shadow: 0 0 3px #c2c7cc;
    
    }
   span.Q{
        background: #c239ec;
        padding: 0px 4px;
        border-radius: 50%;
        color: white;
        margin-right: 5px;
    }
    span.T{
        background: #00c8f8;
        padding: 0px 6px;
        border-radius: 50%;
        color: white;
        margin-right: 5px;
    }
    i.fa.fa-times.close-btn {
        float: right;
        font-size: 22px;
        margin-right: 5px;
        cursor: pointer;
    }
    .autolist-custom{
     /* //   background:#efefef; */
        width:100%;
        padding:5px;
        margin:0;
    }
    /*****************************Module Section css end here*************************/