.kt-form__actions{
    display:flex;
}
.innersecton{
box-shadow: 0 0 5px #ccc;
padding: 15px;
margin-bottom: 32px;
border-radius:8px;
}
.btn.btn-primary.btn-sm.p-1.ml-1.mb-3.add-option-btn{
    background: #337aab;
    border: #337aab;
    padding: 7px 11px !important;
    box-shadow: 2px 2px 4px #000;
}

.btn.btn-primary.btn-sm.p-1.ml-1.mb-3.add-option-btn:hover{
    background: #000;
    border: #000;
}



.right-side-section{
    float:right;
}
.right-side-section i{
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
}
.sortlist{
    width:100%;
}
.sort-div{
    cursor: all-scroll;
}
.blue-background-class{
    background:#f3f6f9;
}
.modal-dialog.modal-md {
    width: 450px;
}
.blue-background-class{
    background:#f3f6f9 !important;
}

.nav-tabs.nav-tabs-line {
    border-bottom: none !important;
}
.nav-tabs.nav-tabs-line {
    margin: 0 !important;
}

.telecall-tab .card.card-custom>.card-header.card-header-tabs-line .nav .nav-link {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left:15px;
    padding-right:15px;
}
.telecall-tab .card-toolbar{
    margin:0 !important;
}
.telecall-tab .card.card-custom>.card-header.card-header-tabs-line li{
    margin:0;
    padding:0;
}
.telecall-tab .card.card-custom>.card-header.card-header-tabs-line .nav .nav-link.active span,.telecall-tab .card.card-custom>.card-header.card-header-tabs-line .nav .nav-link.active span i {
color:  #6993ff;
}
.btn.btn-light-primary {
    color: #6993ff;
    background-color: #e1e9ff;
    border-color: transparent;
}

.telecall-tab  .nav.nav-bold .nav-item .nav-link .nav-text {
    font-weight: 500;
}
    