.gutter-b {
    margin-bottom: 25px;
}
a.card-title.font-weight-bolder.text-dark-75.font-size-h5.mb-2.text-hover-primary{
    font-size: 1.25rem !important;
}
a.card-title.font-weight-bolder.text-dark-75.font-size-h5.mb-2.text-hover-primary{
    color: #464E5F !important;
}
a.card-title.font-weight-bolder.text-dark-75.font-size-h5.mb-2.text-hover-primary{
    font-weight: 600 !important;
}
span.font-weight-bold.text-muted.font-size-lg {
    font-size: 1.08rem;
}
span.font-weight-bold.text-muted.font-size-lg {
    color: #B5B5C3 !important;
}
span.font-weight-bold.text-muted.font-size-lg {
    font-weight: 500 !important;
}
.fa-plus-circle{
    font-size: 34px;
    background: black;
    color: #f8f8fb;
    border-radius: 59%;
}

.fa-minus-circle{
    font-size: 34px;
    background: black;
    color: #f8f8fb;
    border-radius: 59%;
}
/* HIDE RADIO */
.mood [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  .mood [type=radio] + div {
    cursor: pointer;
    font-size:50px;
    text-align: center;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

  }
  .mood .last-mood [type=radio] + div {
    border-right: 1px solid black;
  }
  
  /* CHECKED STYLES */
 .mood  [type=radio]:checked + div {
    /* //outline: 2px solid #f00; */
    background:#ccc;
   /* // font-size:50px; */
  }

  .header-brown{
    background: #f8f7fb !important;
  }
  .card-header1 {
    border: 1px solid #ccc !important;
    background: #f7f7fa;
}
.card-header1 .nav-pills .nav-item .nav-link:active,  .card-header1 .nav-pills .nav-item .nav-link.active,  .card-header1 .nav-pills .nav-item .nav-link.active:hover {
    background-color: #bdbdc3 !important;
    color: #fdfdfd !important;
    border-radius:0;
    position: relative;
}
span.nav-link.active.custom-design:after {
    content: "";
    background: #bdbdc3;
    position: absolute;
    left: 42%;
    z-index: 7;
    width: 13px;
    height: 13px;
    bottom: -5px;
    transform: rotate(45deg);
}