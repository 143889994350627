.nav-pills{
    margin:0 !important;
}

span.date {
    width: 100px;
    text-align: center;
    color: #111111;
}
.badge-outlined{
    background:#fff  !important;
}
.badge-danger{
    border:1px solid red;
    color:red !important;
}
.badge-primary{
    border:1px solid#5867dd;
    color:#5867dd !important;
}
.badge-warning{
    border:1px solid #ffb822;
    color:#ffb822 !important;
}
.badge-success{
    border:1px solid green;
    color:green !important;
}
span.title b{
    font-size: 19px;
    color: #111;
    font-weight: 500;
    padding-top: 5px;
    display: inline-block;
}


.card.card-custom.gutter-b {
    
    margin:0;
}
select.form-control.filter{
    display: inline-block;
    width: auto;
    margin-left: 20px;
}
.tasks-list{
    margin:auto 100px;
}
.tasks-list ul li{
    font-size:18px;
}
.tasks-list ul{
    list-style: none;
    color:#111;
}
.tasks-list h5{
    color:#111;
}
.tasks-list ul li i {
    margin-right: 8px;
    color: #f8f8fb;
    font-size: 15px;
    background: green;
    border-radius: 50%;
}