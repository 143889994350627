@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/timegrid/main.css';
.card.card-custom {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    border: 0;
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #ECF0F3;
    border-radius: 0.42rem;
    margin:10px;
}
.card.card-custom > .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}

.card-header:first-child {
    border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}
.card-header {
    padding: 2rem 2.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #ECF0F3;
}

.card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #212121;
}
.card.card-custom > .card-header .card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.card.card-custom > .card-header .card-title .card-label {
    margin: 0 0.75rem 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #212121;
}

.card.card-custom > .card-header .card-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.btn.btn-light-primary {
    color: #6993FF;
    background-color: #E1E9FF;
    border-color: transparent;
}
.btn:not(.btn-text) {
    cursor: pointer;
}
.btn {
    outline: none !important;
    vertical-align: middle;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}
.font-weight-bold {
    font-weight: 500 !important;
}

.fc-unthemed .fc-list-heading .fc-widget-header {
    padding: 0.75rem 1.25rem;
}


.fc-unthemed .fc-list-heading .fc-list-heading-main, .fc-unthemed .fc-list-heading .fc-list-heading-alt {
    font-size: 1rem;
    font-weight: 400;
}
.fc-unthemed .fc-list-heading .fc-list-heading-main {
    font-weight: 500;
    text-transform: uppercase;
}
.fc-unthemed .fc-list-heading .fc-list-heading-main {
    color: #464E5F;
}
.fc-unthemed .fc-list-heading .fc-list-heading-main, .fc-unthemed .fc-list-heading .fc-list-heading-alt {
    font-size: 1rem;
    font-weight: 400;
}
.fc-unthemed .fc-list-heading .fc-list-heading-alt {
    color: #B5B5C3;
}
list-heading td {
    background: #F3F6F9;
}
.fc-unthemed .fc-list-heading td {
    background: #ECF0F3;
    border-color: #ECF0F3;
}

.fc-unthemed .fc-list-heading .fc-widget-header {
    padding: 0.75rem 1.25rem;
}



.fc-unthemed .fc-list-heading .fc-widget-header {
    padding: 0.75rem 1.25rem; }
  
  .fc-unthemed .fc-list-heading .fc-list-heading-main,
  .fc-unthemed .fc-list-heading .fc-list-heading-alt {
    font-size: 1rem;
    font-weight: 400; }
  
  .fc-unthemed .fc-list-heading .fc-list-heading-main {
    font-weight: 500;
    text-transform: uppercase; }
  
  .fc-unthemed .fc-list-item td {
    padding: 0.75rem 1.25rem; }
  
  .fc-unthemed .fc-list-item .fc-event-dot {
    border-radius: 50%; }
  
  .fc-unthemed .fc-event,
  .fc-unthemed .fc-event-dot {
    padding: 0;
    border-radius: 0.42rem; }
    .fc-unthemed .fc-event .fc-content,
    .fc-unthemed .fc-event-dot .fc-content {
      padding: 0.55rem 0.55rem 0.55rem 2rem; }
      .fc-unthemed .fc-event .fc-content:before,
      .fc-unthemed .fc-event-dot .fc-content:before {
        display: block;
        content: " ";
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 0.7rem;
        left: 0.75rem; }
    .fc-unthemed .fc-event.fc-not-start.fc-end .fc-content,
    .fc-unthemed .fc-event-dot.fc-not-start.fc-end .fc-content {
      padding-left: 0.5rem; }
    .fc-unthemed .fc-event .fc-time,
    .fc-unthemed .fc-event-dot .fc-time {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 500; }
    .fc-unthemed .fc-event .fc-title,
    .fc-unthemed .fc-event-dot .fc-title {
      font-size: 0.9rem;
      font-weight: 400; }
  
  .fc-unthemed .fc-description {
    font-size: 0.9rem;
    margin-top: 0.25rem;
    font-weight: normal; }
  
  .fc-unthemed .fc-list-item-title > a {
    font-size: 1rem;
    font-weight: 500; }
  
  .fc-unthemed a.fc-more {
    font-size: 0.9rem;
    font-weight: 500; }
  
  .fc-unthemed .fc-popover {
    border-radius: 0.42rem; }
    .fc-unthemed .fc-popover .fc-header {
      padding: 0.75rem 1.25rem;
      border-top-left-radius: 0.42rem;
      border-top-right-radius: 0.42rem; }
      .fc-unthemed .fc-popover .fc-header .fc-close {
        margin-top: 0.35rem; }
      .fc-unthemed .fc-popover .fc-header .fc-title {
        font-weight: 400; }
    .fc-unthemed .fc-popover .fc-body .fc-event-container {
      padding: 1.25rem 1.25rem 0.75rem  1.25rem; }
    .fc-unthemed .fc-popover .fc-body .fc-event {
      margin-bottom: 0.5rem;
      border-radius: 0.42rem; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  
  .fc-unthemed .fc-toolbar {
    margin-bottom: 1.5rem; }
    .fc-unthemed .fc-toolbar h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 0.75rem; }
    .fc-unthemed .fc-toolbar .fc-button {
      outline: none !important;
      height: 2.75rem;
      padding: 0 1.25rem;
      font-size: 1rem; }
      .fc-unthemed .fc-toolbar .fc-button.fc-corner-left {
        border-top-left-radius: 0.42rem;
        border-bottom-left-radius: 0.42rem; }
      .fc-unthemed .fc-toolbar .fc-button.fc-corner-right {
        border-top-right-radius: 0.42rem;
        border-bottom-right-radius: 0.42rem; }
      .fc-unthemed .fc-toolbar .fc-button .fc-icon {
        font-size: 1.1rem; }
        .fc-unthemed .fc-toolbar .fc-button .fc-icon:after {
          display: none; }
        .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow {
          font-family: Ki;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: ""; }
        .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow {
          font-family: Ki;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: ""; }
  
  .fc-unthemed .fc-axis > span {
    font-size: 0.9rem; }
  
  .ui-draggable-handle {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab; }
  
  @media (max-width: 1199.98px) {
    .fc-unthemed .fc-toolbar {
      margin-bottom: 1.5rem; }
      .fc-unthemed .fc-toolbar .fc-left,
      .fc-unthemed .fc-toolbar .fc-right,
      .fc-unthemed .fc-toolbar .fc-center {
        display: block;
        float: none;
        margin-bottom: 1rem;
        text-align: center; }
        .fc-unthemed .fc-toolbar .fc-left h2,
        .fc-unthemed .fc-toolbar .fc-right h2,
        .fc-unthemed .fc-toolbar .fc-center h2 {
          text-align: center;
          float: none; }
        .fc-unthemed .fc-toolbar .fc-left > .fc-button-group,
        .fc-unthemed .fc-toolbar .fc-right > .fc-button-group,
        .fc-unthemed .fc-toolbar .fc-center > .fc-button-group {
          display: inline-block;
          float: none; }
          .fc-unthemed .fc-toolbar .fc-left > .fc-button-group > .fc-button,
          .fc-unthemed .fc-toolbar .fc-right > .fc-button-group > .fc-button,
          .fc-unthemed .fc-toolbar .fc-center > .fc-button-group > .fc-button {
            float: none; }
        .fc-unthemed .fc-toolbar .fc-left > .fc-button,
        .fc-unthemed .fc-toolbar .fc-right > .fc-button,
        .fc-unthemed .fc-toolbar .fc-center > .fc-button {
          float: none; } }
  
  .fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
    border-color: #ECF0F3; }
  
  .fc-unthemed td.fc-today {
    background: rgba(105, 147, 255, 0.025); }
  
  .fc-unthemed .fc-day-grid-event {
    margin: 0; }
  
  .fc-unthemed .fc-event,
  .fc-unthemed .fc-event-dot {
    background: #fff;
    border: 1px solid #ECF0F3;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055); }
    .fc-unthemed .fc-event.fc-not-start.fc-not-end,
    .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end {
      border-left: 1px solid #F3F6F9; }
    .fc-unthemed .fc-event.fc-start .fc-content:before,
    .fc-unthemed .fc-event-dot.fc-start .fc-content:before {
      background: #ECF0F3; }
    .fc-unthemed .fc-event .fc-time,
    .fc-unthemed .fc-event-dot .fc-time {
      color: #464E5F; }
    .fc-unthemed .fc-event .fc-title,
    .fc-unthemed .fc-event-dot .fc-title {
      color: #464E5F; }
  
  .fc-unthemed .fc-description {
    color: #B5B5C3; }
  
  .fc-unthemed .fc-list-item-title > a[href]:hover {
    color: #6993FF;
    text-decoration: none; }
  
  .fc-unthemed .fc-more {
    color: #B5B5C3; }
  
  .fc-unthemed .fc-event.fc-event-primary.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-primary.fc-start .fc-content:before {
    background: #6993FF; }
  
  .fc-unthemed .fc-event.fc-event-solid-primary.fc-start, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-end {
    background: #6993FF; }
    .fc-unthemed .fc-event.fc-event-solid-primary.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-end .fc-title {
      color: #FFFFFF; }
    .fc-unthemed .fc-event.fc-event-solid-primary.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-end .fc-description {
      color: #FFFFFF; }
    .fc-unthemed .fc-event.fc-event-solid-primary.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-primary.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-end .fc-time {
      color: #FFFFFF; }
  
  .fc-unthemed .fc-event.fc-event-secondary.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-secondary.fc-start .fc-content:before {
    background: #E5EAEE; }
  
  .fc-unthemed .fc-event.fc-event-solid-secondary.fc-start, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-end {
    background: #E5EAEE; }
    .fc-unthemed .fc-event.fc-event-solid-secondary.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-end .fc-title {
      color: #464E5F; }
    .fc-unthemed .fc-event.fc-event-solid-secondary.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-end .fc-description {
      color: #464E5F; }
    .fc-unthemed .fc-event.fc-event-solid-secondary.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-secondary.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-end .fc-time {
      color: #464E5F; }
  
  .fc-unthemed .fc-event.fc-event-success.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-success.fc-start .fc-content:before {
    background: #1BC5BD; }
  
  .fc-unthemed .fc-event.fc-event-solid-success.fc-start, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-end {
    background: #1BC5BD; }
    .fc-unthemed .fc-event.fc-event-solid-success.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-success.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-success.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-success.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-success.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-info.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-info.fc-start .fc-content:before {
    background: #8950FC; }
  
  .fc-unthemed .fc-event.fc-event-solid-info.fc-start, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-end {
    background: #8950FC; }
    .fc-unthemed .fc-event.fc-event-solid-info.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-info.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-info.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-info.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-info.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-warning.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-warning.fc-start .fc-content:before {
    background: #FFA800; }
  
  .fc-unthemed .fc-event.fc-event-solid-warning.fc-start, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-end {
    background: #FFA800; }
    .fc-unthemed .fc-event.fc-event-solid-warning.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-warning.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-warning.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-warning.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-danger.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-danger.fc-start .fc-content:before {
    background: #F64E60; }
  
  .fc-unthemed .fc-event.fc-event-solid-danger.fc-start, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-end {
    background: #F64E60; }
    .fc-unthemed .fc-event.fc-event-solid-danger.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-danger.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-danger.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-danger.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-light.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-light.fc-start .fc-content:before {
    background: #F3F6F9; }
  
  .fc-unthemed .fc-event.fc-event-solid-light.fc-start, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-end {
    background: #F3F6F9; }
    .fc-unthemed .fc-event.fc-event-solid-light.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-end .fc-title {
      color: #80808F; }
    .fc-unthemed .fc-event.fc-event-solid-light.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-end .fc-description {
      color: #80808F; }
    .fc-unthemed .fc-event.fc-event-solid-light.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-light.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-light.fc-not-start.fc-end .fc-time {
      color: #80808F; }
  
  .fc-unthemed .fc-event.fc-event-dark.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-dark.fc-start .fc-content:before {
    background: #212121; }
  
  .fc-unthemed .fc-event.fc-event-solid-dark.fc-start, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-end {
    background: #212121; }
    .fc-unthemed .fc-event.fc-event-solid-dark.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-dark.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-dark.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-dark.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-white.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-white.fc-start .fc-content:before {
    background: #ffffff; }
  
  .fc-unthemed .fc-event.fc-event-solid-white.fc-start, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-end {
    background: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-white.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-end .fc-title {
      color: #464E5F; }
    .fc-unthemed .fc-event.fc-event-solid-white.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-end .fc-description {
      color: #464E5F; }
    .fc-unthemed .fc-event.fc-event-solid-white.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-white.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-white.fc-not-start.fc-end .fc-time {
      color: #464E5F; }
  
  .fc-unthemed .fc-event.fc-event-dark-75.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-event-dark-75.fc-start .fc-content:before {
    background: #464E5F; }
  
  .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-start, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-start,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-end {
    background: #464E5F; }
    .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-start .fc-title, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-title, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-start .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-title,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-end .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-start .fc-description, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-description, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-start .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-description,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-end .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-start .fc-time, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-time, .fc-unthemed .fc-event.fc-event-solid-dark-75.fc-not-start.fc-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-start .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-time,
    .fc-unthemed .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-end .fc-time {
      color: #ffffff; }
  
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-popover .fc-header,
  .fc-unthemed .fc-list-heading td {
    background: #F3F6F9; }
  
  .fc-unthemed .fc-time-grid-event {
    overflow: hidden; }
  
  .fc-unthemed .fc-popover {
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.075);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.075);
    background: #fff; }
    .fc-unthemed .fc-popover .fc-header {
      background: #fff;
      border-bottom: 1px solid #ECF0F3; }
      .fc-unthemed .fc-popover .fc-header .fc-close {
        color: #B5B5C3; }
        .fc-unthemed .fc-popover .fc-header .fc-close:hover {
          color: #464E5F; }
      .fc-unthemed .fc-popover .fc-header .fc-title {
        color: #464E5F; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end .fc-content:before {
      background: #ECF0F3; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-primary.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-primary.fc-not-start.fc-not-end .fc-content:before {
      background: #6993FF; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end {
      background: #6993FF; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-title {
        color: #FFFFFF; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-description {
        color: #FFFFFF; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-primary.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-primary.fc-not-start.fc-not-end .fc-time {
        color: #FFFFFF; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-secondary.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-secondary.fc-not-start.fc-not-end .fc-content:before {
      background: #E5EAEE; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end {
      background: #E5EAEE; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-title {
        color: #464E5F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-description {
        color: #464E5F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-secondary.fc-not-start.fc-not-end .fc-time {
        color: #464E5F; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-success.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-success.fc-not-start.fc-not-end .fc-content:before {
      background: #1BC5BD; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-success.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end {
      background: #1BC5BD; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-success.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-success.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-info.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-info.fc-not-start.fc-not-end .fc-content:before {
      background: #8950FC; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-info.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end {
      background: #8950FC; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-info.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-info.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-warning.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-warning.fc-not-start.fc-not-end .fc-content:before {
      background: #FFA800; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end {
      background: #FFA800; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-warning.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-warning.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-danger.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-danger.fc-not-start.fc-not-end .fc-content:before {
      background: #F64E60; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end {
      background: #F64E60; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-danger.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-danger.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-light.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-light.fc-not-start.fc-not-end .fc-content:before {
      background: #F3F6F9; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-light.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end {
      background: #F3F6F9; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-title {
        color: #80808F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-description {
        color: #80808F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-light.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-light.fc-not-start.fc-not-end .fc-time {
        color: #80808F; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-dark.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-dark.fc-not-start.fc-not-end .fc-content:before {
      background: #212121; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end {
      background: #212121; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-white.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-white.fc-not-start.fc-not-end .fc-content:before {
      background: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-white.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end {
      background: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-title {
        color: #464E5F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-description {
        color: #464E5F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-white.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-white.fc-not-start.fc-not-end .fc-time {
        color: #464E5F; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-dark-75.fc-not-start.fc-not-end .fc-content:before,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-dark-75.fc-not-start.fc-not-end .fc-content:before {
      background: #464E5F; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end {
      background: #464E5F; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-title,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-title {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-description,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-description {
        color: #ffffff; }
      .fc-unthemed .fc-popover .fc-body .fc-event.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-time,
      .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-event-solid-dark-75.fc-not-start.fc-not-end .fc-time {
        color: #ffffff; }
  
  .fc-unthemed th.fc-day-header > a,
  .fc-unthemed th.fc-day-header > span {
    color: #B5B5C3; }
  
  .fc-unthemed .fc-list-heading td {
    background: #ECF0F3;
    border-color: #ECF0F3; }
  
  .fc-unthemed .fc-list-heading .fc-list-heading-main {
    color: #464E5F; }
  
  .fc-unthemed .fc-list-heading .fc-list-heading-alt {
    color: #B5B5C3; }
  
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-popover .fc-header,
  .fc-unthemed .fc-list-heading td {
    background: #F3F6F9; }
  
  .fc-unthemed .fc-list-item:hover td {
    background: transparent !important; }
  
  .fc-unthemed .fc-list-item .fc-event-dot {
    background: #ECF0F3;
    border-color: #ECF0F3; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-primary .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-primary .fc-event-dot {
    background: #6993FF;
    border-color: #6993FF; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-secondary .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-secondary .fc-event-dot {
    background: #E5EAEE;
    border-color: #E5EAEE; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-success .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-success .fc-event-dot {
    background: #1BC5BD;
    border-color: #1BC5BD; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-info .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-info .fc-event-dot {
    background: #8950FC;
    border-color: #8950FC; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-warning .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-warning .fc-event-dot {
    background: #FFA800;
    border-color: #FFA800; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-danger .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-danger .fc-event-dot {
    background: #F64E60;
    border-color: #F64E60; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-light .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-light .fc-event-dot {
    background: #F3F6F9;
    border-color: #F3F6F9; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-dark .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-dark .fc-event-dot {
    background: #212121;
    border-color: #212121; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-white .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-white .fc-event-dot {
    background: #ffffff;
    border-color: #ffffff; }
  
  .fc-unthemed .fc-list-item.fc-event-solid-dark-75 .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-dark-75 .fc-event-dot {
    background: #464E5F;
    border-color: #464E5F; }
  
  .fc-unthemed .fc-toolbar .fc-button {
    color: #B5B5C3;
    background: transparent;
    border: 1px solid #ECF0F3;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
    .fc-unthemed .fc-toolbar .fc-button .fc-icon {
      color: #B5B5C3; }
    .fc-unthemed .fc-toolbar .fc-button:hover {
      border: 0;
      background: #F3F6F9;
      border: 1px solid #ECF0F3;
      color: #B5B5C3; }
    .fc-unthemed .fc-toolbar .fc-button:focus, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button.fc-button-active {
      background: #6993FF;
      color: #FFFFFF;
      border: 1px solid #6993FF;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-shadow: none; }
      .fc-unthemed .fc-toolbar .fc-button:focus .fc-icon, .fc-unthemed .fc-toolbar .fc-button:active .fc-icon, .fc-unthemed .fc-toolbar .fc-button.fc-button-active .fc-icon {
        color: #FFFFFF; }
    .fc-unthemed .fc-toolbar .fc-button.fc-button-disabled {
      opacity: 0.7; }

      span.badge.badge-success {
       margin-right:10px;
    }


    .symbol.symbol-60.symbol-2by3 .symbol-label {
      height: 60px;
      width: 90px;
  }
  .icon-size{
  font-size: 39px;
    color: #e13b92;
  }