.symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
}
.symbol1.symbol-lg-75 > img {
    width: 100%;
    max-width: 75px;
    height: 75px;
    border-radius: 50%;

}
.font-size-h4 {
    font-size: 1.35rem !important;
}

.text-muted {
    color: #B5B5C3 !important;
}
.font-weight-bold {
    font-weight: 500 !important;
}
.card.card-custom > .card-body {
    padding: 2rem 2.25rem;
}

.mb-7, .my-7 {
    margin-bottom: 1.75rem !important;
}
.text-center {
    text-align: center !important;
}

.text-dark-75 {
    color: #464E5F !important;
}
.font-weight-bolder {
    font-weight: 600 !important;
}
a.text-hover-primary:hover, .text-hover-primary:hover{
    color: #6993FF !important;
}


.btn.btn-light-facebook {
    color: #3b5998;
    background-color: rgba(0, 150, 136, 0.1);
    border-color: transparent;
}

.btn.btn-light-facebook i {
    color: #009688;
}
.btn.btn-light-notification {
    color: #3b5998;
    background-color: rgba(233, 30, 99, 0.1);
    border-color: transparent;
}

.btn.btn-light-notification i {
    color: #E91E63;
}
.btn.btn-light-twitter {
    color: #1da1f2;
    background-color: rgba(29, 161, 242, 0.1);
    border-color: transparent;
}
.btn.btn-light-twitter i {
    color: #1da1f2;
}
.btn.btn-light-linkedin {
    color: #0077b5;
    background-color: rgba(0, 119, 181, 0.1);
    border-color: transparent;
}
.btn.btn-light-linkedin i {
    color: #0077b5;
}

.separator.separator-solid {
    border-bottom: 1px solid #eef0f8;
}

.tab-pane.fade{
    display:none;
}
.tab-pane.fade.active.show{
    display:block;
}






/*************Notes*****************/

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}
.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}
.card.card-custom > .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}
.card-header:first-child {
    border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}
.card-header {
    padding: 2rem 2.25rem;
    margin-bottom: 0;
}
.card.card-custom > .card-header .card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #212121;
}
.text-dark {
    color: #212121 !important;
}
.font-size-sm {
    font-size: 0.925rem;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    color: #F64E60;
    background-color: #FFE2E5;
    border-color: transparent;
}
.card.card-custom > .card-header .card-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.dropdown-inline {
    display: inline-block;
}
.font-size-lg {
    font-size: 1.08rem;
}

.text-dark {
    color: #212121 !important;
}
.font-weight-bolder {
    font-weight: 600 !important;
}
.font-size-sm {
    font-size: 0.925rem;
}

.text-dark-50 {
    color: #80808F !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}
.mb-10, .my-10 {
    margin-bottom: 2.5rem !important;
}
/************************************/

h3, .h3 {
    font-size: 1.5rem;
    color: #464E5F;
}
/*****************************************/

.bg-light-warning {
    background-color: #FFF4DE !important;
}
.bg-light-success {
    background-color: #C9F7F5 !important;
}
.bg-light-danger {
    background-color: #FFE2E5 !important;
}
.bg-light-info {
    background-color: #EEE5FF !important;
}
.p-5 {
    padding: 1.25rem !important;
}

.mb-9, .my-9 {
    margin-bottom: 2.25rem !important;
}
.svg-icon.svg-icon-success svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #1BC5BD !important;
}
.svg-icon.svg-icon-warning svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800 !important;
}
.svg-icon.svg-icon-danger svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60 !important;
}
.svg-icon.svg-icon-info svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #8950FC !important;
}
/******************************************/




















.readMore{
    text-align: right;
    color: #181824;;
}

li.nav-item {
    font-size: 15px;
    padding: 10px;
  
    color: #181824;
}
li.active.nav-item{
    border-bottom: 2px solid hsl(0, 0%, 37.6%);
}

    
    .main-title {
    margin: 0 0 16PX;;
    padding: 0 1rem 0 0;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    }
    ul.sharing-links {
        margin: 0;
        padding: 0;
        list-style: none;
        float: right;
    }
    ul.sharing-links li {
        display: inline-block;
        margin-left: 20px;
    }

    .nofication-icon{
        margin-right: 2rem !important;
    }

   

    @media (min-width: 550px){
        .modal-content{
             min-width: 500px; 
        } 
    }