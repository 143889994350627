.action-btn{
    color: #000 !important;
    padding: 8px;
    font-size: 15px;
}
.badge.badge-success.custom-color,span.badge.badge-danger.custom-color{
color: white !important;
    border: none;
}

th.MuiTableCell-root.MuiTableCell-head .MUIDataTableHeadCell-sortActive-139, .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #6993ff!important;
}