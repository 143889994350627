// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

div#kt_content {
    padding-top: 0;
}
.font-weight-bold {
    font-weight: 500 !important;
}
h2, .h2 {
    font-size: 1.75rem;
}
@media screen and (max-width: 1024px) {
    .symbol.symbol-35 .symbol-label{
        background: #7096f6 !important;
     }
  }
  .header-progress-bar .progress{
      background-color:transparent !important;
  }