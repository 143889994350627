@charset "UTF-8";
.kt-wizard-v1 {
  flex-direction: column; }
  .kt-wizard-v1 .kt-wizard-v1__nav {
    border-bottom: 1px solid #eeeef4; }
    .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto; }
      .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex: 1; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:after {
          font-family: Flaticon2;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: "";
          font-size: 1.25rem;
          color: #a2a5b9;
          margin-left: 0.5rem;
          margin-right: 0.5rem; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
          content: none; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 2rem 1rem;
          text-align: center; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
            font-size: 3.7rem;
            color: #a2a5b9;
            margin-bottom: 0.5rem; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill] {
              transition: fill 0.3s ease;
              fill: #a2a5b9; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill] {
              transition: fill 0.3s ease; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label {
            font-size: 1.1rem;
            font-weight: 500;
            color: #74788d; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"]:after, .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"]:after {
          color: #5d78ff; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon, .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
          color: #5d78ff; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill], .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill] {
            transition: fill 0.3s ease;
            fill: #5d78ff; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill], .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill] {
            transition: fill 0.3s ease; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label, .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label {
          color: #5d78ff; }
      .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items.kt-wizard-v1__nav-items--clickable .kt-wizard-v1__nav-item {
        cursor: pointer; }
      @media (max-width: 1399px) {
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
          width: 100%; } }
      @media (max-width: 768px) {
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
          flex-direction: column;
          align-items: flex-start;
          padding: 2rem 0; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
            flex: 0 0 100%;
            position: relative;
            width: 100%; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:after {
              position: absolute;
              right: 2rem; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
              content: "\f105";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 2rem;
              color: #a2a5b9; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
              flex-direction: row;
              justify-content: flex-start;
              flex: 0 0 100%;
              padding: 0.5rem 2rem; }
              .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
                font-size: 1.5rem;
                margin-right: 1rem;
                margin-bottom: 0; } }
  .kt-wizard-v1 .kt-wizard-v1__wrapper {
    display: flex;
    justify-content: center;
    background-color: #f9fafc; }
    .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
      width: 90%;
      padding: 4rem 0 5rem; }
      .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #eeeef4; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__form {
          margin-top: 3rem; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid #eeeef4; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item .kt-wizard-v1__review-title {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: 0.7rem; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item .kt-wizard-v1__review-content {
            line-height: 1.8rem; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0; }
      .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        @media (max-width: 576px) {
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
            flex-wrap: wrap;
            justify-content: center; } }
        @media (max-width: 576px) {
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions .btn {
            margin: 0 0.5rem 1rem; } }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
          margin-right: auto; }
          @media (max-width: 576px) {
            .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
              margin-right: 0.5rem; } }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
          margin: auto 0 auto auto; }
          @media (max-width: 576px) {
            .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
              margin: 0 0 1rem; } }
    @media (max-width: 768px) {
      .kt-wizard-v1 .kt-wizard-v1__wrapper {
        padding: 2rem; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
          width: 100%;
          padding: 2rem 1rem 4rem; } }
  .kt-wizard-v1.kt-wizard-v1--white .kt-wizard-v1__wrapper {
    background-color: #ffffff; }
  .kt-wizard-v1 [data-ktwizard-type="step-info"] {
    display: none; }
    .kt-wizard-v1 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v1 [data-ktwizard-type="step-content"] {
    display: none; }
    .kt-wizard-v1 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v1 [data-ktwizard-type="action-prev"] {
    display: none; }
  .kt-wizard-v1 [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v1 [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="first"] [data-ktwizard-type="action-prev"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="first"] [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
    display: inline-block; }
.border-line{
    border:1px solid #72717752;
    width: 100%;
    margin-bottom: 24px;
}
.kt-form__actions {
    border-top: 1px solid #eeeef4;
    padding-top: 20px;
}
.full-wdith{
  width:100%;
}
.kt-subheader__separator {
  background: #dbdce7;
}
.kt-subheader__separator.kt-subheader__separator--v {
  width: 1px;
  height: 22px;
}
.kt-subheader__separator {
 display: inline-block; 
  width: 12px;
  height: 4px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 1rem 0 0;
}
.badge-Updated,.badge-selected-1 {
  color: green;
  background: #b2e0b2;
}
.badge-Requested {
  color: #d48630;
  background: #f9eaea;
}
.badge-Cancelled {
  color: #212121;
  background: #dad3d3;
}
.badge-Pending {
  color: #c11402;
  background: #e4bfbb;
}
.badge-call.due,.badge-Tracking.due {
  color: #d48630;
  background: #fff;
  border:1px solid #d48630;
}
.badge-Scheduled{
  color: #48a4f8;
  background: #fff;
  border:1px solid #48a4f8;
}
span.badge.badge-Completed{
  color: green;
  background: #fff;
  border:1px solid green;

}
span.mode-Online {
  display: block;
  background: green;
  width: 8px;
  height: 8px;
  z-index: 1;
  margin-right: 2px;
  border-radius: 5px;
}

span.mode-Offline {
  display: block;
  background: #aaa;
  width: 8px;
  height: 8px;
  z-index: 1;
  margin-right: 2px;
  border-radius: 5px;
}
.jEDPQU {
  height: auto !important;
 
}
.alert.alert-danger {
  background: #e61711 !important;
  border: 1px solid #e61711 !important;
  color: #ffffff;
  font-size: 16px;
}
i.fa.fa-exclamation.main-validation-error {
  border: 2px solid white;
  padding: 2px 9px;
  border-radius: 50%;
  margin-right: 10px;
}
.fields-container .form-control{
	display:none;
}