.action-btn{
    color: #000 !important;
    padding: 8px;
    font-size: 15px;
}
.badge.badge-success.custom-color,span.badge.badge-danger.custom-color{
color: white !important;
    border: none;
}

th.MuiTableCell-root.MuiTableCell-head .MUIDataTableHeadCell-sortActive-139, .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #6993ff!important;
}

.keyList th.MuiTableCell-root.MuiTableCell-head[data-colindex="2"], .keyList td.MuiTableCell-root[data-colindex="2"],.keyList th.MuiTableCell-root.MuiTableCell-head[data-colindex="3"], .keyList td.MuiTableCell-root[data-colindex="3"],.hide{display:none!important;}
.inner-Headings td{color:#b5b5c3 !important;}
.MuiTableRow-root.heading-agenda {
    background-color: #e6e2e2;
}

.keyList .files-container a{margin-right:10px;}
.keyList tr ul,.tab-container .card-header-tabs-line{padding:0;}