.blue-background-class{
    background:#f3f6f9 !important;
    cursor: all-scroll;
}

.sortlist-2 .list-group-item{
    background-image: linear-gradient(to bottom right, #fff, #e4e4e424);
}

.action-btn{
    color: #000 !important;
    padding: 8px;
    font-size: 15px;
}
.badge.badge-success.custom-color,span.badge.badge-danger.custom-color{
color: white !important;
    border: none;
}

th.MuiTableCell-root.MuiTableCell-head .MUIDataTableHeadCell-sortActive-139, .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #6993ff!important;
}

.callList th.MuiTableCell-root.MuiTableCell-head[data-colindex="1"], .callList td.MuiTableCell-root[data-colindex="1"],.hide{display:none!important;}
.inner-Headings td{color:#b5b5c3 !important;}
.MuiTableRow-root.heading-agenda {
    background-color: #e6e2e2;
}